export default {
	cinema: {
		address: 'ул. Валерии Гнаровской, 12',
		place: null,
		phoneNumbers: ['8 (3452) 385-444'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/azbukakino72',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBFlnHEfgD',
		selectButton: {
			options: [{
				value: 'https://azbukakino.com',
				title: 'ТРЦ «Фаворит»',
			},
			{
				value: 'https://azbuka-kino.com',
				title: 'ТРЦ «Солнечный»',
			}],
			selectedOptionValue: 'https://azbukakino.com',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
